import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ProgramaPersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ProgramaPersonaPage",
            ss: ss,
            ajax: {
                "url": ss.indexProgramaPersona(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'created_at', name: 'created_at', title: 'Fecha preinscripcion' },
                { data: 'CI', name: 'CI', title: 'Carnet' },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'Programa', name: 'Programa', title: 'Programa' },
                { data: 'NivelAcademico', name: 'NivelAcademico', title: 'Nivel Academico' },
                
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadesAcademicas: [],
            nivelesAcademicos: [],
            gestiones: [],
            programas: [],
            programaPersonas: [],
            programaPersona: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            options: [],
            filtro: {},
            tab: 1,
            personaElegida: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
        };
    },
    methods: {
        getUnidadesAcademicas() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadesAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getNivelesAcademicos() {
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.nivelesAcademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGestiones() {
            this.ss.listGestiones().then(
                (result) => {
                    let response = result.data;
                    this.gestiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProgramas() {
            let params = {
                Gestion: this.filtro.Gestion,
                Periodo: this.filtro.Periodo,
                UnidadAcademica: this.filtro.UnidadAcademica,
            }
            this.ss.listPrograma(params).then(
                (result) => {
                    let response = result.data;
                    this.programas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        deshacerFiltros(){
            this.filtro={}
        },
        newProgramaPersona() {
            this.programaPersona = {};
            this.$refs['frm-programaPersona'].show();
        },
        showProgramaPersona(id) {
            this.ss.showProgramaPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.programaPersona = response.data;
                    //this.$refs['view-programaPersona'].show();
                    this.$refs['frm-programaPersona'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        editProgramaPersona() {
            this.$refs['frm-programaPersona'].show();
            this.$refs['view-programaPersona'].hide();
        },
        cancelProgramaPersona() {
            if (this.programaPersona.id) {
                this.$refs['view-programaPersona'].show();
            }
            this.$refs['frm-programaPersona'].hide();
        },
        saveProgramaPersona() {
            this.ss.storeProgramaPersona(this.programaPersona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-programaPersona'].hide();
                    this.$refs['datatable-programaPersona'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteProgramaPersona() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyProgramaPersona(this.programaPersona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-programaPersona'].hide();
                                this.$refs['datatable-programaPersona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        buscarPersona(search, loading){
            loading(true);
            this.ss.select2Persona(search).then((res) => {

                this.options = res.data.items;
                loading(false);
            })
        },
        asignarPersona(value){
            this.programaPersona.Persona = value.id;
            this.personaElegida = value;
        },
        loadFileBoucher() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programaPersona.Boucher = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
        },


        loadFileSolicitud() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programaPersona.Solicitud = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
		},

		loadFileContrato() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programaPersona.Contrato = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
        },
        
        draw() {
            window.$('.btn-datatable-ProgramaPersona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showProgramaPersona(data);
            });
        },
        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getUnidadesAcademicas();
            this.getGestiones();
            this.getNivelesAcademicos();
        }
    }
};
