import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'VerProgramaPostgradoPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,
			errorBag: {},
			idPrograma:0,
			programa:{},
			loginCredenciales:{},
			persona:{},
			programaPersona:{},
			progPer:{},
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
			auth: {},
            estaInscrito:false,
            isLoadingFile:false,
            isLoadingFile2:false,
            datosFaltantes:[],
            nit:"",
            nombreNit:"",
            nuevaOrden:{},
            urlPago:"",
            botonPago:false,
            puntoVenta:""
			
		};

    },
    components: {
		MenuExterno,
		FooterExterno,
    },
	props: {
		msg: String
    },
    // created(){
    //     this.$root.$refs.VerProgramaPostgrado=this;
    // },
	methods: {

        prueba(param){
            console.log("hola",param);
        },
		showProgramaPost() {
            
            this.isLoading=true;
            this.ss.showProgramaPostPrograma(this.idPrograma).then(
                (result) => {
                    let response = result.data;
					this.programa = response.data;
					this.getProgramaPersona();

                }
            ).catch(error => {
                console.log(error);
               
            });
		},

		loadFileBoucher() {
            this.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programaPersona.Boucher = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
		},
		
		loadFileSolicitud() {
            //vm.isLoadingFile = true;
            this.isLoadingFile2 = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programaPersona.Solicitud = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile2 = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile2 = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
		},

		loadFileContrato() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programaPersona.Contrato = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
		},

		abrirModalLogin(){
			// this.tiponivelacademico = {};
            this.$refs['modalLogin'].show();
        },
        
        abrirModalDatosFactura(){
			// this.tiponivelacademico = {};
            this.$refs['DatosFactura'].show();
            this.nit= this.persona.FacturaNIT;
            this.nombreNit= this.persona.FacturaNombre;
        },
        cerrarModalDatosFactura(){
			// this.tiponivelacademico = {};
            this.$refs['DatosFactura'].hide();
        },

        saveDatosFactura() {
            this.persona.FacturaNIT=this.nit ;
            this.persona.FacturaNombre=this.nombreNit;

            this.ss.storeDatosFactura(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
					console.log(response);
                    this.$refs['DatosFactura'].hide();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
		},
        
		abrirModalRegistro(){
			// this.tiponivelacademico = {};
            this.$refs['modalRegistro'].show();
		},

		cancelInscripcion(){
            this.$refs['ModalInscripcion'].hide();
		},

		saveInscripcion() {
			this.programaPersona.Programa = this.programa.id;
            this.programaPersona.Persona = this.auth.id;
            
            console.log("saveInscripcion");
            console.log(this.programaPersona);
            this.ss.storeNuevaInscripcion(this.programaPersona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.getProgramaPersona();
					console.log(response);
					this.estaInscrito=true;
                    this.$refs['ModalInscripcion'].hide();
                    console.log("exito");
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    console.log("error");
                });
                 
		},
        // salioMal(){
        //     console.log("algo salio mal");
        // },

        // salioMal23(){
        //     console.log("algo salio mal 23");
        // },
        
        menulog(){
            this.$root.$refs.MenuExterno.abrirModalLogin();
        },
		verificarDatosCompletos(){

			this.auth = JSON.parse(localStorage.getItem("persona"));
            this.ss.getToken();
			if(this.auth == null || this.auth == ""){
				// this.$swal({
				// 	title: "Usted no Inició Sesion",
				// 	text: "Por favor logueate, Luego podras inscribirte",
				// 	icon: "warning",
				// 	buttons: true,
                //     dangerMode: true,});
                    

                    this.$swal({
                        title: "Usted no Inició Sesion",
                        text: "Por favor inicia sesión, Luego podras inscribirte",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                this.$root.$refs.MenuExterno.abrirModalLogin();
                                // router.push("yourroutename");
                                    
                            } else {
                                //swal("Your imaginary file is safe!");
                            }
                        });

					return;
			}
			
            this.ss.verificarDatosPersona(this.auth.id).then(
                (result) => {
                    let response = result.data;
					console.log(response);
					if(response.success == false){
						this.$swal({
							title: "Porfavor completa tus datos en tu perfil",
							text: "Luego podras inscribirte",
							icon: "warning",
							buttons: true,
                            dangerMode: true,
                            
                        })
                        .then((willDelete) => {
                            if (willDelete) {
                                this.$router.push('MiPerfil');
                                    
                            } else {
                                //swal("Your imaginary file is safe!");
                            }
                        });
                       
					}else{
						this.inscribirse();
					}
					this.datosFaltantes=response.data;
					// this.programa = response.data;
					// this.getProgramaPersona();

                }
            ).catch(error => {
                console.log(error);
               
            });
		},
		inscribirse(){
			// if( this.auth == null){
			// 	//this.$root.$refs.$App.$refs.$MenuExterno.$refs.abrirModalLogin();
			// 	//this.$root.$refs.MenuExterno.abrirModalLogin();
			// 	console.log("No esta logeado");
			// 	return;
			// }

			this.$refs['ModalInscripcion'].show();

			// if( this.persona == null){
			// 	this.$refs['modalLogin'].show();
			// 	return;
			// }
			// this.$swal({
            //     title: "Estas seguro que deseas inscribirte?",
            //     text: "Esta accion es irreversible!",
            //     icon: "warning",
            //     buttons: true,
            //     dangerMode: true,
            // })
            //     .then((willDelete) => {
			// 		if (willDelete) {
			// 			console.log("Se quiere inscribir");
			// 			this.saveProgramaPersona();
			// 		}else{
			// 			console.log("NO Se quiere inscribir");
			// 		}
					
            //     });
			
		},
		descargarCont(){
			console.log("descargar");
			window.open(this.rutaApp+"storage/Docs/Contrato.pdf");
		},

		descargarSol(){
			console.log("descargar");
			window.open(this.rutaApp+"storage/Docs/Solicitud.doc");
		},

		descargarAfiche(){
			// console.log(this.programa.Triptico);
			// console.log(this.rutaApp +"storage/documents/" + this.programa.Triptico);
			window.open(this.rutaApp +"storage/documents/" + this.programa.Triptico);
		},

		getProgramaPersona()
		{
			if(this.auth == null || this.programa==null  ){
				return;
			}
			let params= {
				idPersona: this.auth.id,
				idPrograma: this.programa.id
			}
            this.ss.VerificarInscripcionProgramaPersona(params).then(
                (result) => {
                    let response = result.data;
					this.progPer = response.data;
					if(this.progPer.id){
						this.estaInscrito=true;
					}
                }
            ).catch(error => {
                console.log(error);
               
            });
		},
		saveProgramaPersona() {
			this.persona = JSON.parse(localStorage.getItem("persona"));
			this.auth = JSON.parse(localStorage.getItem("persona"));
			this.programaPersona.Persona=this.auth.id;
			this.programaPersona.Programa=this.programa.id;
            this.ss.storeProgramaPersona(this.programaPersona).then(
                (result) => {
                    console.log(result);
					let response = result.data;
					this.getProgramaPersona();
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
		login() {
			this.ss.login(this.loginCredenciales)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						console.log("logueado");
						this.login= true;
						this.$refs['modalLogin'].hide();
						this.persona=result.data.data;
						
						//this.$router.push('/');
						/*var persona = JSON.parse(localStorage.getItem('persona'));
						console.log(persona)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
        },
        

        cancelPago(){
            // this.urlPago="";
            // this.botonPago=false;
            // this.nuevaOrden={};
            // this.$refs['ModalPago'].hide();
            
            location.reload();
        },

        cerrarModalPago(){
            // this.urlPago="";
            // this.botonPago=false;
            // this.nuevaOrden={};
            // this.$refs['ModalPago'].hide();
            
            location.reload();
        },
        

        pagoOnline(){
            this.saveInscripcion().then(console.log("termino la promesa sin error"),console.log("termino la promesa error"));
            //respStore.then(console.log("termino la promesa"));
        },


        OrderKE(){

            
            this.nuevaOrden={};
            this.botonPago=true;
            this.urlPago="";

            // console.log("solicutuddddd")
            // console.log(!this.programaPersona.Solicitud )
            // console.log(!this.progPer.Solicitud)

            // if(this.progPer==null){
            //     if(this.programaPersona.Solicitud==null){
            //         // console.log("no subio la solicitud")
    
            //         this.$swal({
            //             title: "Falta un documento",
            //             text: "Por favor sube tu solicitud",
            //             icon: "warning",
            //             dangerMode: true,
            //         })
                    
            //         this.botonPago=false;
            //         return;
    
            //     }
            // }
            


            //datos para la inscripcion
            this.nuevaOrden.Programa=this.programa.id;
            this.nuevaOrden.Persona=this.persona.id;
            this.nuevaOrden.Solicitud=this.programaPersona.Solicitud;
            this.nuevaOrden.TipoPago=this.programaPersona.TipoPago;

            // datos para la orden de pago

            // console.log("boton de pagos");
            this.nuevaOrden.Amount=this.programa.CostoPrograma*100;
            //this.nuevaOrden.ProgramaPersona=5;
            this.nuevaOrden.AdditionalData=[];
            let aditionalDataItem=["MATRICULA " + this.programa.Programa, this.programa.CostoPrograma]
            this.nuevaOrden.AdditionalData.push(aditionalDataItem);
            
            if(this.programa.UnidadAcademica==2){
                this.puntoVenta="la paz";
            }else if(this.programa.UnidadAcademica==3){
                this.puntoVenta="santa cruz";
            }else if(this.programa.UnidadAcademica==4){
                this.puntoVenta="cochabamba";
            }else if(this.programa.UnidadAcademica==5){
                this.puntoVenta="riberalta";
            }else if(this.programa.UnidadAcademica==6){
                this.puntoVenta="trópico";
            }

            

            let iDataItems=[
                            ["CELULAR ", this.persona.Celular],
                            ["CARNET ", this.persona.CI],
                            ["NOMBRE COMPLETO", this.persona.Persona],
                            ["EMAIL", this.persona.email],
                            ["NIT", this.persona.FacturaNIT],
                            ["NOMBRE FACTURA", this.persona.FacturaNombre],
                            //["punto_venta", "postgrado"]
                            ["punto_venta", this.puntoVenta]
                            
                           ];
            this.nuevaOrden.IData=iDataItems;

            this.nuevaOrden.InvoiceData={
                "InvoiceDetails":[
                    {
                        "id_concepto":5094, 
                        "descripcion":"MATRICULA " + this.programa.Programa,
                        "cantidad":100,
                        "precio_unitario":this.programa.CostoPrograma*100,
                        "aplica_descuento":"N",	
                        "porcentaje_descuento":0,
                        "importe_descuento":"0"
                    }
                ]
            };


            console.log("-----------------nueva orden---------------------")
            console.log(this.nuevaOrden);
            console.log("-----------------nueva orden---------------------")


            //console.log(this.nuevaOrden);
            this.ss.OrderKE(this.nuevaOrden).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log(response);
                    // this.$bvToast.toast(
                    //     response.msg,
                    //     {
                    //         title: 'Correcto',
                    //         variant: 'success',
                    //         autoHideDelay: 5000
                    //     }
                    // )
                    // window.open(response.data.item.back_url);
                    // console.log(response.data.item.back_url);
                    this.urlPago = response.data.item.back_url;
                    console.log=(this.urlPago);
                    this.botonPago=false;
                    this.$refs['ModalPago'].show();

                    //window.open(this.urlPago , "ventana1" , "width=500,height=500,scrollbars=NO");
                })
                .catch(error => {
                    //this.errorBag = error.response.data.errors;
                    console.log(error);
                    this.botonPago=false;
                    this.$bvToast.toast(
                        'Ocurrio un error al momento de realizar el pago',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                //console.log("aaaaaa");
        },


        pagarDeNuevo(){


            
            this.nuevaOrden={};
            this.botonPago=true;
            this.urlPago="";

            //datos para la inscripcion
            this.nuevaOrden.Programa=this.programa.id;
            this.nuevaOrden.Persona=this.persona.id;
            //this.nuevaOrden.Solicitud=this.programaPersona.Solicitud;
            //this.nuevaOrden.TipoPago=this.programaPersona.TipoPago;

            // datos para la orden de pago

            // console.log("boton de pagos");
            this.nuevaOrden.Amount=this.programa.CostoPrograma*100;
            //this.nuevaOrden.ProgramaPersona=5;
            this.nuevaOrden.AdditionalData=[];
            let aditionalDataItem=["MATRICULA " + this.programa.Programa, this.programa.CostoPrograma]
            this.nuevaOrden.AdditionalData.push(aditionalDataItem);



            if(this.programa.UnidadAcademica==2){
                this.puntoVenta="la paz";
            }else if(this.programa.UnidadAcademica==3){
                this.puntoVenta="santa cruz";
            }else if(this.programa.UnidadAcademica==4){
                this.puntoVenta="cochabamba";
            }else if(this.programa.UnidadAcademica==5){
                this.puntoVenta="riberalta";
            }else if(this.programa.UnidadAcademica==6){
                this.puntoVenta="trópico";
            }

            let iDataItems=[
                            ["CELULAR ", this.persona.Celular],
                            ["CARNET ", this.persona.CI],
                            ["NOMBRE COMPLETO", this.persona.Persona],
                            ["EMAIL", this.persona.email],
                            ["NIT", this.persona.FacturaNIT],
                            ["NOMBRE FACTURA", this.persona.FacturaNombre],
                            //["punto_venta", "postgrado"]
                            ["punto_venta", this.puntoVenta]
                            
                           ];
            this.nuevaOrden.IData=iDataItems;


            this.nuevaOrden.InvoiceData={
                "InvoiceDetails":[
                    {
                        "id_concepto":5094, 
                        "descripcion":"MATRICULA " + this.programa.Programa,
                        "cantidad":100,
                        "precio_unitario":this.programa.CostoPrograma*100,
                        "aplica_descuento":"N",	
                        "porcentaje_descuento":0,
                        "importe_descuento":"0"
                    }
                ]
            };

            //console.log(this.nuevaOrden);
            this.ss.pagarDeNuevo(this.nuevaOrden).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log("inscripcion");
                    console.log(response);
                    
                    // this.$bvToast.toast(
                    //     response.msg,
                    //     {
                    //         title: 'Correcto',
                    //         variant: 'success',
                    //         autoHideDelay: 5000
                    //     }
                    // )
                    // window.open(response.data.item.back_url);
                    // console.log(response.data.item.back_url);
                    this.urlPago = response.data.item.back_url;
                    console.log=(this.urlPago);
                    this.botonPago=false;
                    this.$refs['ModalPago'].show();

                    //window.open(this.urlPago , "ventana1" , "width=500,height=500,scrollbars=NO");
                })
                .catch(error => {
                    //this.errorBag = error.response.data.errors;
                    console.log(error);
                    this.$bvToast.toast(
                        'Ocurrio un error al momento de realizar el pago',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.botonPago=false;
                //console.log("aaaaaa");
        },

        verInscripcion(){
			this.$router.push('DetalleMiPrograma?id='+this.programa.id) 
        },

        async funcion1(){
            console.log("esto es function 1");
            // this.programaPersona.Programa = this.programa.id;
            // this.programaPersona.Persona = this.auth.id;
            this.programaPersona.MontoDeposito = this.programa.CostoPrograma;
            this.programaPersona.Boucher = "pago online";
            
            let respuesta = await this.saveInscripcion();
            console.log(respuesta);
            console.log("despues de store nueva inscripcion");
        },
        

        iniciarRegistro(){

            console.log("iniciar");
            console.log(this.auth)
            if(this.auth==null ){
                console.log("iniciar registro");
                console.log( this.$route.query.modal);
    
                if(this.$route.query.modal==1 ){
                    
    
                    console.log("abrir modal registro");
                    // this.$refs['modalRegistro'].show();
                    this.$root.$refs.MenuExterno.abrirModalRegistro();
                    // this.abrirModalRegistro();
                }
                else{
                    console.log("no hacer nada");
                }
            }
            else{
                console.log("no mostrar modal");
            }
           
            
        },

        abrirVideo(){
            this.$refs['ModalVideo'].show();
        },

        cerrarModalVideo(){
            this.$refs['ModalVideo'].hide();
        }

	},
	mounted() {
        // console.log( this.$route.query.modal);
        // if(this.$route.query.modal==1){
        //     this.abrirModalRegistro();
        // }
        
        
        window.scrollTo(0, 0);
		this.idPrograma = this.$route.query.id;
		this.showProgramaPost();
		this.persona = JSON.parse(localStorage.getItem("persona"));
		this.auth = JSON.parse(localStorage.getItem("persona"));
        this.iniciarRegistro();
		this.getProgramaPersona();
		
	}
}