import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'MisProgramasPostgradoPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,
			loginRequest: {},
			errorBag: {},
			MisProgramas:{},
			slide: 0,
			sliding: null,
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
		};

	},
	props: {
		msg: String
	},
	methods: {
		// getProgramasPost() {
        //     this.ss.listVistaPostgradoPrograma().then(
        //         (result) => {
        //             let response = result.data;
        //             this.programasPost = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
		// },
		getProgramasPost() {
			let params = {
				id: this.auth.id,
			}
            this.ss.listInscripciones(params).then(
                (result) => {
                    let response = result.data;
                    this.MisProgramas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},
		// verMas(id){
		// 	console.log("ver mas"+id);
		// 	this.$router.push('VerProgramaPostgrado?id='+id) 
			
		// 	//router.go('/VerificarDare');
		// },
		// onSlideStart() {
		// 	this.sliding = true
		// },
		// onSlideEnd() {
		// 	this.sliding = false
		// }
		verProgramas(){
			this.$router.push('inscribete');
		},

		verDetalle(id){
			console.log("detalle");
			console.log("ver mas"+id);
			this.$router.push('DetalleMiPrograma?id='+id); 
		}

	},
	mounted() {
		window.scrollTo(0, 0);
		// this.getProgramasPost();
		this.auth = JSON.parse(localStorage.getItem('persona'));
        console.log(this.auth);
        if (!this.auth) {
            this.$router.push('/Login');
        } else {
			this.getProgramasPost();
            // this.showPersona();
            // this.getRol();
            // this.getUnidadAcademica();
        }
	}
}