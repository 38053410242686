import MainService from "@/services/MainService.js";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReportesPage",
    data() {
        let ss = new MainService();
        return {
            ss: ss,
            isLoading: false,
            errorBag: {},
            filtro: {},
            unidadesAcademicas: {},
            programaElegido: {},
            UnidadAcademicaElegida: {},
            personaElegida: {},
            gestiones: {},
            programas:{},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            options: [],
        };
    },
    methods: {
        buscarPersona(search, loading){
            loading(true);
            this.ss.select2Persona(search).then((res) => {
                this.options = res.data.items;
                loading(false);
            })
        },
        asignarPersona(value){
            this.personaElegida.id = value.id;
            this.personaElegida = value;
        },
        modalElegirPrograma(){
            this.$refs['reporte-inscritos-por-programa'].show();
        },
        modalElegirPrograma2(){
            this.$refs['reporte-inscritos-programas'].show();
        },
        modalElegirPersona3(){
            this.$refs['reporte-hoja-datos'].show();
        },
        getUnidadesAcademicas() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadesAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGestiones() {
            this.ss.listGestiones().then(
                (result) => {
                    let response = result.data;
                    this.gestiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProgramas() {
            let params = {
                Gestion: this.filtro.Gestion,
                Periodo: this.filtro.Periodo,
                UnidadAcademica: this.filtro.UnidadAcademica,
            }
            this.ss.listPrograma(params).then(
                (result) => {
                    let response = result.data;
                    this.programas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        generarReportePorPrograma(){
            //Si no encuentra el id del programa, botar error
            if(this.programaElegido.id == null){
                this.$swal({
                    icon: 'warning',
                    title: 'Atencion!',
                    text: 'Debe elegir un programa!',
                    showConfirmButton: false,
                    timer: 1500
                })
                return;
            }
            // var urlFile = this.rutaApp+'api/reporteInscritosPorCurso?Programa='+this.programaElegido.id;
            var urlFile = this.rutaApp+'api/reporteDetalladoInscritos?Programa='+this.programaElegido.id;
            window.open(urlFile);
        },
        generarReporte2(){
            //Si no encuentra el id de la unidad academica, botar error
            if(this.UnidadAcademicaElegida.id == null){
                this.$swal({
                    icon: 'warning',
                    title: 'Atencion!',
                    text: 'Debe elegir una unidad academica!',
                    showConfirmButton: false,
                    timer: 1500
                })
                return;
            }
            var urlFile = this.rutaApp+'api/numeroInscritosPorCurso?UnidadAcademica='+this.UnidadAcademicaElegida.id;
            window.open(urlFile);
        },

       

        generarReporte3(){
            if(this.personaElegida.id == null){
                this.$swal({
                    icon: 'warning',
                    title: 'Atencion!',
                    text: 'Debe elegir una persona!',
                    showConfirmButton: false,
                    timer: 1500
                })
                return;
            }
            var urlFile = this.rutaApp+'api/hojaDeDatos?Persona='+this.personaElegida.id;
            window.open(urlFile);
        },

        excelInscritosPorPrograma(){

            if(this.programaElegido.id == null){
                this.$swal({
                    icon: 'warning',
                    title: 'Atencion!',
                    text: 'Debe elegir un programa!',
                    showConfirmButton: false,
                    timer: 1500
                })
                return;
            }
            
            let url = "api/get_excel?Programa="+this.programaElegido.id;
            console.log(this.rutaApp+url);
            window.open(this.rutaApp+url);
        },

        deshacerFiltros(){

        },
        draw() {
            
        }
    },
    components: {
        Loader
    },
    mounted() {
        this.getUnidadesAcademicas();
        this.getGestiones();
    }
};
