import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "VerificarTesoreriaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "VerificarTesoreriaPage",
            ss: ss,
            ajax: {
                "url": ss.indexSinValidarTesoreria(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'created_at', name: 'created_at', title: 'Fecha preinscripcion' },
                { data: 'CI', name: 'CI', title: 'Carnet' },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'Programa', name: 'Programa', title: 'Programa' },
                { data: 'NivelAcademico', name: 'NivelAcademico', title: 'Nivel Academico' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personaprograma: {},
            persona: {},
            // especialidad: {},
            isLoading: false,
            errorBag: {},
            generarCorreo:false,
            generarCodigoSaga:false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            botonObservacion:false,
        };
    },
    methods: {

        showInscripcion(id) {
            console.log("show");
            this.generarCodigoSaga=false;
            this.isLoading=true;
            this.ss.showInscripcionProgramaPersona(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.personaprograma = response.data;
                    this.persona = response.dataPer;
                    this.$refs['view-inscipcion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, 

        cancelarModalBoucher() {

            this.$refs['view-inscipcion'].hide();
        },

        verDetalles() {
            this.$refs['view-inscipcion'].hide();
            this.$refs['view-inscipcionDetalles'].show();
        },

        volverModalBoucher() {
            this.$refs['view-inscipcionDetalles'].hide();
            this.$refs['view-inscipcion'].show();
        },

        registrarOffice365(){
            console.log("Llamo a registrar office");
            // this.generarCorreo=true;
            // axios.get( '/nuevos/registrarOffice365', { params: { id: vm.preinscripcion.id }})
            //     .then( result => {
            //         response = result.data;
            //         if( response.success ) {

            //             vm.preinscripcion.correoInstitucional = response.data.userPrincipalName;
            //             this.guardarCorreo();
            //             //toastr.success("Se creo el correo institucional", 'Correcto!');
            //         } else {
            //             toastr.error("Ocurrio un error , intente nuevamente", 'Oops!');
            //             this.generarCorreo=false;
            //         }
            //     })
            //     .catch( error => {
            //         console.log("Error 2 ", error);
            //         toastr.error("Ocurrio un error al crear al correo, intente nuevamente", 'Oops!');
            //         this.generarCorreo=false;
            //     })
        },

        guardarMonto(){
            console.log("guardar monto");
            // axios.post(urlStorePreinscripcion, vm.preinscripcion)
            // .then(result => {
            //     response = result.data;
            //     vm.preinscripcion = response.data;
            //     /* console.log(vm.preinscripcion);
            //     debugger; */
            //     swal("Exito!", "Se guardo el monto", "success");
            //     //var nuevos_inscritosTable = $('#nuevos_inscritos-table').DataTable();
            //     //nuevos_inscritosTable.draw();
            //     //$('#modalEditar').modal('hide');
            //     //$('#view-preinscripcion').modal('show');
            //     vm.guardarCorreoMonto=true;
            // })
            // .catch(error => {
            //     //console.log(error);
            //     swal("Ocurrio un error!", "...", "error");
            // });
            // return "";
        },

        insertarSaga(){
            console.log("insertar saga");
            this.generarCodigoSaga=true;
            // axios.get( '/nuevos/insertarSaga', { params: { id: vm.preinscripcion.id }})
            //     .then( result => {
            //         response = result.data;
            //         console.log(response);
            //         if( response.success ) {
            //             console.log("Exito al registrar en el saga", response.msg);
            //             //vm.preinscripcion.CodigoSaga = response.resp;
            //             //toastr.success(response.msg, 'Correcto!');
                        
            //             if(response.codigo == '1'){
            //                 swal("Codigo Saga:"+response.data.CodAlumno, "El usuario ya esta registrado en el SAGA!", "warning");
            //             }else if(response.codigo == '2'){
            //                 swal("Codigo Saga:"+response.data.CodAlumno, "Se registro al usuario en el SAGA!", "success");
            //                 //vm.preinscripcion.CodigoSaga = response.data.CodigoSaga
            //             }
            //             vm.preinscripcion.CodigoSaga = response.data.CodAlumno;
            //             vm.preinscripcion.correoInstitucional = response.data.email_emi;
            //             this.generarCodigoSaga=false;
            //             this.guardarCorreo();
            //             //swal("Exito!", "Porfavor vuelva a intentar", "success");
            //         } else {
            //             toastr.error(response.msg, 'Oops!');
            //             this.generarCodigoSaga=false;
            //         }
            //     })
            //     .catch( error => {
            //         this.generarCodigoSaga=false;
            //         if(error.status == 500){
            //             swal("Error!", "Porfavor vuelva a intentar", "error");
            //         }else{
            //             console.log("Error 2 ", error);
            //             toastr.error(error, 'Oops 2!');
            //         }
            //     })
        },


        validarComoTesoreria() {
            console.log("validar como tesoreria");
            console.log(this.personaprograma.id);
            this.ss.validarTesoreria(this.personaprograma).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['view-inscipcionDetalles'].hide();
                    this.$refs['datatable-sinvalidarTesoreria'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        enviarCorreoObvs() {
            this.$refs['enviarObservacion'].show();
        },



        enviarObvs() {
            this.botonObservacion=true;
            console.log("enviar observacion");
            // console.log(this.personaprograma.id);
            this.ss.enviarObservacionTesoreria(this.personaprograma).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['enviarObservacion'].hide();
                    this.botonObservacion=false;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonObservacion=false;
                });
        },
        

        cancelarCorreoObvs() {
            this.personaprograma.ObservacionDare=null;
            this.$refs['enviarObservacion'].hide();
        },

        generarReporteHD(){
            if(this.persona.id == null){
                this.$swal({
                    icon: 'warning',
                    title: 'Atencion!',
                    text: 'Debe elegir una persona!',
                    showConfirmButton: false,
                    timer: 1500
                })
                return;
            }
            
            // var urlFile = this.rutaApp+'api/hojaDeDatos?Persona='+this.persona.id;
            var urlFile = this.rutaApp+'api/hojaDeDatosDos?Persona='+this.persona.id+'&Programa='+this.personaprograma.NPrograma;
            window.open(urlFile);
        },


        draw() {
            window.$('.btn-datatable-VerificarTesoreriaSinValidar').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInscripcion(data);
            });
        },
    },
    components: {
        dataTable,
        Loader
    },

    computed: {
        // a computed getter
        nombreEstado: function () {
            if(this.personaprograma.Estado == 0){
                return "Pre-inscripcion sin verificar";
            }else if(this.personaprograma.Estado == 1){
                return "Verificado por el DARE";
            }else if(this.personaprograma.Estado == 2){
                return "Verificado por Tesoreria";
            }
          return ""
        },

        // nombreUA: function () {
        //     if(this.preinscripcion.UnidadAcademica == 1){
        //         return "La Paz";
        //     }else if(this.preinscripcion.UnidadAcademica == 2){
        //         return "Santa Cruz";
        //     }else if(this.preinscripcion.UnidadAcademica == 3){
        //         return "Cochabamba";
        //     }else if(this.preinscripcion.UnidadAcademica == 4){
        //         return "Riberalta";
        //     }else if(this.preinscripcion.UnidadAcademica == 5){
        //         return "Shinahota";
        //     }

        //   return ""
        // },

        tieneCorreo: function(){
            if(this.persona.correoInstitucional!=null || this.persona.correoInstitucional==""){
                return true;
            }
            return false
        },

        tieneCodigoSaga: function(){
            if(this.persona.CodigoSaga!=null || this.persona.CodigoSaga==""){
                return true;
            }
            return false
        }

    },

    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        // else{
        //     this.getNivelAcademico();
        // }
    }
};
