import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "VerificarDarePage",
    data() {
        let ss = new MainService();
        return {
            msg: "VerificarDarePage",
            ss: ss,
            ajax: {
                "url": ss.indexValidadosVerificarDare(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'created_at', name: 'created_at', title: 'Fecha preinscripcion' },
                { data: 'CI', name: 'CI', title: 'Carnet' },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'Programa', name: 'Programa', title: 'Programa' },
                { data: 'NivelAcademico', name: 'NivelAcademico', title: 'Nivel Academico' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personaprograma: {},
            persona: {},
            // especialidad: {},
            isLoading: false,
            errorBag: {},
            generarCorreo:false,
            generarCodigoSaga:false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            botonCredenciales:false,
            botonSiscoin:false,

        };
    },
    methods: {

        showInscripcion(id) {
            console.log("show");
            this.generarCodigoSaga=false;
            this.isLoading=true;
            this.ss.showInscripcionProgramaPersona(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.personaprograma = response.data;
                    this.persona = response.dataPer;
                    this.$refs['view-inscipcion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, 

        cancelarModalBoucher() {

            this.$refs['view-inscipcion'].hide();
        },

        verDetalles() {
            this.$refs['view-inscipcion'].hide();
            this.$refs['view-inscipcionDetalles'].show();
        },

        volverModalBoucher() {
            this.$refs['view-inscipcionDetalles'].hide();
            this.$refs['view-inscipcion'].show();
        },

        registrarOffice365(){
            this.generarCorreo=true;
            this.ss.registrarOffice365(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if( response.success ) {
                        this.$swal({
                            icon: 'warning',
                            title: response.data.userPrincipalName,
                            text: 'Exito correo institucional creado!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.persona.correoInstitucional = response.data.userPrincipalName;
                        //this.guardarCorreo();
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: "Error",
                            text: 'Ocurrio un error, vuelva a intentar',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.generarCorreo=false;
                    }
                }
            )
            .catch( error => {
                console.log(error);
                this.generarCorreo=false;
                this.$bvToast.toast(
                    "Ocurrio un error...",
                    {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            })
            
        },


        guardarMonto() {
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    // this.$refs['frm-persona'].hide();
                    // this.$refs['datatable-persona'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        insertarSaga(){
            console.log("insertar saga");
            this.generarCodigoSaga=true;
            this.ss.insertarSaga(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if( response.success ) {
                        if(response.codigo == '1'){
                            this.$swal({
                                icon: 'warning',
                                title: "Codigo Saga:"+response.data.CodAlumno,
                                text: 'El usuario ya esta registrado en el SAGA!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            
                        }else if(response.codigo == '2'){
                            this.$swal({
                                icon: 'success',
                                title: "Codigo Saga:"+response.data.CodAlumno,
                                text: 'Se registro al usuario en el SAGA!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        this.persona.CodigoSaga = response.data.CodAlumno;
                        //this.persona.correoInstitucional = response.data.email_emi;
                        this.generarCodigoSaga=false;
                        //this.guardarCorreo();
                    }else{
                        this.$swal({
                            icon: 'warning',
                            title: "Ocurrio un error",
                            text: 'Vuelva a intentarlo',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.generarCodigoSaga=false;
                    }
                    // this.personaprograma = response.data;
                    // this.persona = response.dataPer;
                    // this.$refs['view-inscipcion'].show();
                    // this.isLoading=false;
                }
            )
            .catch( error => {
                console.log(error);
                this.generarCodigoSaga=false;
                if(error.status == 500){
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ocurrio un error vuelva a intentar!',
                    })
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ocurrio un error...',
                    })
                }
            })
            // axios.get( '/nuevos/insertarSaga', { params: { id: vm.preinscripcion.id }})
            //     .then( result => {
            //         response = result.data;
            //         console.log(response);
            //         if( response.success ) {
            //             console.log("Exito al registrar en el saga", response.msg);
            //             //vm.preinscripcion.CodigoSaga = response.resp;
            //             //toastr.success(response.msg, 'Correcto!');
                        
            //             if(response.codigo == '1'){
            //                 swal("Codigo Saga:"+response.data.CodAlumno, "El usuario ya esta registrado en el SAGA!", "warning");
            //             }else if(response.codigo == '2'){
            //                 swal("Codigo Saga:"+response.data.CodAlumno, "Se registro al usuario en el SAGA!", "success");
            //                 //vm.preinscripcion.CodigoSaga = response.data.CodigoSaga
            //             }
            //             vm.preinscripcion.CodigoSaga = response.data.CodAlumno;
            //             vm.preinscripcion.correoInstitucional = response.data.email_emi;
            //             this.generarCodigoSaga=false;
            //             this.guardarCorreo();
            //             //swal("Exito!", "Porfavor vuelva a intentar", "success");
            //         } else {
            //             toastr.error(response.msg, 'Oops!');
            //             this.generarCodigoSaga=false;
            //         }
            //     })
            //     .catch( error => {
            //         this.generarCodigoSaga=false;
            //         if(error.status == 500){
            //             swal("Error!", "Porfavor vuelva a intentar", "error");
            //         }else{
            //             console.log("Error 2 ", error);
            //             toastr.error(error, 'Oops 2!');
            //         }
            //     })
        },

        enviarCredenciales(){
            this.botonCredenciales=true;
            this.ss.enviarFinalizacionInscripcion(this.personaprograma.id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        "Se enviaron las credenciales",
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )

                    this.botonCredenciales=false;
                }
                
            )
            .catch( error => {
                console.log(error);
                this.generarCodigoSaga=false;
                this.$bvToast.toast(
                    "Ocurrio un error al enviar el correo",
                    {
                        title: 'Oops',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )

                this.botonCredenciales=false;
            })
            // axios.get( '/nuevos/insertarSaga', { params: { id: vm.preinscripcion.id }})
            //     .then( result => {
            //         response = result.data;
            //         console.log(response);
            //         if( response.success ) {
            //             console.log("Exito al registrar en el saga", response.msg);
            //             //vm.preinscripcion.CodigoSaga = response.resp;
            //             //toastr.success(response.msg, 'Correcto!');
                        
            //             if(response.codigo == '1'){
            //                 swal("Codigo Saga:"+response.data.CodAlumno, "El usuario ya esta registrado en el SAGA!", "warning");
            //             }else if(response.codigo == '2'){
            //                 swal("Codigo Saga:"+response.data.CodAlumno, "Se registro al usuario en el SAGA!", "success");
            //                 //vm.preinscripcion.CodigoSaga = response.data.CodigoSaga
            //             }
            //             vm.preinscripcion.CodigoSaga = response.data.CodAlumno;
            //             vm.preinscripcion.correoInstitucional = response.data.email_emi;
            //             this.generarCodigoSaga=false;
            //             this.guardarCorreo();
            //             //swal("Exito!", "Porfavor vuelva a intentar", "success");
            //         } else {
            //             toastr.error(response.msg, 'Oops!');
            //             this.generarCodigoSaga=false;
            //         }
            //     })
            //     .catch( error => {
            //         this.generarCodigoSaga=false;
            //         if(error.status == 500){
            //             swal("Error!", "Porfavor vuelva a intentar", "error");
            //         }else{
            //             console.log("Error 2 ", error);
            //             toastr.error(error, 'Oops 2!');
            //         }
            //     })
        },


        validarComoDare() {
           
            // console.log(this.personaprograma.id);
            this.ss.validarDare(this.personaprograma).then(
                (result) => {
                    // console.log(result);
                    let response = result.data;
                    console.log("validar como dare");
                    console.log(response);
                    
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['view-inscipcionDetalles'].hide();
                    this.$refs['datatable-sinValidar'].reload();
                    
                    
                })
                .catch((error) => {
                    console.log(error);
                    //this.errorBag = error.response.data.errors;
                    
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        enviarCorreoObvs() {
            this.$refs['enviarObservacion'].show();
        },



        enviarObvs() {
            console.log("enviar observacion");
            // console.log(this.personaprograma.id);
            this.ss.enviarObservacionDare(this.personaprograma).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['enviarObservacion'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        

        cancelarCorreoObvs() {
            this.personaprograma.ObservacionDare=null;
            this.$refs['enviarObservacion'].hide();
        },

        filtrarSinVerificar(){
            console.log("Sin verificar");
        },
        filtrarVerificados(){
            console.log("Ya verificados");
        },


        buscarSiscoin(){
            this.botonSiscoin=true;
            this.ss.buscarSiscoin(this.persona.CI).then(
                (result) => {
                    // console.log("exito: ", result);
                    let response = result.data;
                    // this.$bvToast.toast(
                    //     response.msg,
                    //     {
                    //         title: 'Correcto',
                    //         variant: 'success',
                    //         autoHideDelay: 5000
                    //     }
                    // )

                    console.log(response.data.success);


                    if(response.data.success){
                        this.$bvToast.toast(
                            'La persona ya esta registrada en el siscoin',
                            {
                                title: 'info',
                                variant: 'info',
                                autoHideDelay: 5000
                            }
                        )
                        this.botonSiscoin=false;
                    }else{
                        this.insertarSiscoin();
                    }
                })
                .catch((error) => {
                    let errores = error.response;
                    console.log("los errores son:" , errores);
                    this.$bvToast.toast(
                        'Problema al buscar en el siscoin',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonSiscoin=false;
                });
        },

        insertarSiscoin(){
            this.ss.insertarSiscoin(this.persona).then(
                (result) => {
                    // console.log("exito: ", result);
                    let response = result.data;
                    this.$bvToast.toast(
                        'Correcto, se inserto en el siscoin',
                        {
                            title: 'info',
                            variant: 'info',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonSiscoin=false;

                    console.log(response.data);
                })
                .catch((error) => {
                    let errores = error.response;
                    console.log("los errores son:" , errores);
                    this.$bvToast.toast(
                        'Problema al insertar, intente de nuevo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonSiscoin=false;
                });
        },

        generarReporteHD(){
            if(this.persona.id == null){
                this.$swal({
                    icon: 'warning',
                    title: 'Atencion!',
                    text: 'Debe elegir una persona!',
                    showConfirmButton: false,
                    timer: 1500
                })
                return;
            }
            // var urlFile = this.rutaApp+'api/hojaDeDatos?Persona='+this.persona.id;
            var urlFile = this.rutaApp+'api/hojaDeDatosDos?Persona='+this.persona.id+'&Programa='+this.personaprograma.NPrograma;
            window.open(urlFile);
        },

        insertarKardex(){
            this.botonKardex=true;
            this.ss.insertarKardex(this.persona).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        'Se registro a la persona en Kardex',
                        {
                            title: 'info',
                            variant: 'info',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonKardex=false;
                })
                .catch((error) => {
                    let errores = error.response;
                    console.log("los errores son:" , errores);
                    this.$bvToast.toast(
                        'Problemas al insertar en Kardex',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonKardex=false;
                });
        },
        
        // draw() {
        //     window.$('.btn-datatable-VerificarDareSinValidar').on('click', (evt) => {
        //         const data = window.$(evt.target)[0].id;
        //         this.showInscripcion(data);
        //     });
        // },
        draw() {
            window.$('.btn-datatable-VerificarDareValidados').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInscripcion(data);
            });
        },
    },
    components: {
        dataTable,
        Loader
    },

    computed: {
        // a computed getter
        nombreEstado: function () {
            if(this.personaprograma.Estado == 0){
                return "Pre-inscripcion sin verificar";
            }else if(this.personaprograma.Estado == 1){
                return "Verificado por el DARE";
            }else if(this.personaprograma.Estado == 2){
                return "Verificado por Tesoreria";
            }
          return ""
        },

        // nombreUA: function () {
        //     if(this.preinscripcion.UnidadAcademica == 1){
        //         return "La Paz";
        //     }else if(this.preinscripcion.UnidadAcademica == 2){
        //         return "Santa Cruz";
        //     }else if(this.preinscripcion.UnidadAcademica == 3){
        //         return "Cochabamba";
        //     }else if(this.preinscripcion.UnidadAcademica == 4){
        //         return "Riberalta";
        //     }else if(this.preinscripcion.UnidadAcademica == 5){
        //         return "Shinahota";
        //     }

        //   return ""
        // },

        tieneCorreo: function(){
            if(this.persona.correoInstitucional!=null || this.persona.correoInstitucional==""){
                return true;
            }
            return false
        },

        tieneCodigoSaga: function(){
            if(this.persona.CodigoSaga!=null || this.persona.CodigoSaga==""){
                return true;
            }
            return false
        }

    },

    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        // else{
        //     this.getNivelAcademico();
        // }
    }
};
