import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'MiPerfilPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,
			errorBag: {},
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
			auth: {},
			persona: {},
			unidadAcademicas: [],
			grados: {},
			tab: 1,
			password: {},
            isLoadingFile:false,
            isLoadingFile2:false,
            isLoadingFile3:false,
			tab2: 1,
            estudioRealizado:{},
            tiposEstudios:[],
            estPostgraduales:[],
            estGrado:[],
			estEscolar:[],
			datosFaltantes:[],
            carreras: [],
            gestiones:[],
            medios:[],
            deptoscolegio:[],
            tiposcolegio:[],

		};

	},
	props: {
		msg: String
	},
	methods: {
		BorrarEstudioRealizado(id){
            console.log(id);
		},
		getEstPostgraduales() {
            this.ss.listPostgradualesDatoAcademico(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.estPostgraduales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},
		getEstGrado() {
            this.ss.listGradoDatoAcademico(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.estGrado = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstEscolar() {
            this.ss.listEscolarDatoAcademico(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.estEscolar = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},
		getTiposEstudios() {
            this.ss.listTipoEstudio().then(
                (result) => {
                    let response = result.data;
                    this.tiposEstudios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},
		agregarEstudios(){
            this.$refs['LLenarDatosAcademicos'].show();
        },
        saveTipoEstudio() {
            this.estudioRealizado.Persona=this.persona.id;
            this.ss.storeDatoAcademico(this.estudioRealizado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.getEstPostgraduales();
                    this.getEstEscolar();
                    this.getEstGrado();
                    this.$refs['LLenarDatosAcademicos'].hide();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cancelTipoEstudio(){
            this.estudioRealizado={};
            this.$refs['LLenarDatosAcademicos'].hide();
		},
		completarDatosAcademicos(){
            this.getEstPostgraduales();
            this.getEstEscolar();
            this.getEstGrado();
            this.$refs['completarDatos'].show();
        },
		loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
		getGrado(){
            let params= {
                "Fuerza":this.persona.Fuerza
            };
            this.ss.listGrado(params).then(
                (result) => {
                    let response = result.data;
                    this.grados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
		getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                    let uas=response.data
                    this.unidadAcademicas = uas.splice(1, 4);
                }
            ).catch(error => {
                console.log(error);
            });
        },
		editarPersona(){
            this.$refs['frm-persona'].show();
            this.getUnidadAcademica();
		},

        
		savePersona() {

            if(this.tab<3){
                this.tab=this.tab + 1;
                return;
            }
            else{
                this.tab=1;
            }

            if(!this.persona.IdGrado){
                this.persona.IdGrado=142;
            }
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
					console.log(response);
					this.persona = response.data;
					this.auth = response.data;

                    
                    this.$refs['frm-persona'].hide();
					localStorage.setItem('persona', JSON.stringify(this.persona));
					localStorage.setItem('auth', JSON.stringify(this.persona));
					this.verificarDatosCompletos();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
		cancelPersona() {
            // if (this.persona.id) {
            //     this.$refs['view-persona'].show();
            // }
            this.$refs['frm-persona'].hide();
        },
		loadFileCarnet() {
            this.isLoadingFile2 = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoCarnet = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile2 = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile2 = false;
                    });
            }
        },

        /*
        loadFileTitulo() {
            this.isLoadingFile3 = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoTitulo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile3 = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile3 = false;
                    });
            }
        },
        */

        loadFileTituloBachiller() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoTituloBachiller = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFileTituloProfesional() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoTituloProfesional = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFileCertificadoNacimiento() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoCertificadoNacimiento = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
		},
		
		verificarDatosCompletos(){
			
            this.ss.verificarDatosPersona(this.auth.id).then(
                (result) => {
                    let response = result.data;
					console.log(response);
					
					this.datosFaltantes=response.data;
                    // console.log(this.datosFaltantes.length);
					// this.programa = response.data;
					// this.getProgramaPersona();

                }
            ).catch(error => {
                console.log(error);
               
            });
		},

        getCarrerasPorUnidadAcademica() {
            let idUA = this.persona.UnidadAcademica;
            //console.log(this.nuevaPersona.UnidadAcademica);
            this.ss.listPorCarreraEspecialidadUA(idUA).then(
                (result) => {
                    let response = result.data;
                    //console.log(response);
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        volverAtras(){
            console.log("redirigir");
            history.back();
        },

        getTipoColegio() {
            this.ss.listTipoColegio().then(
                (result) => {
                    let response = result.data;
                    this.tiposcolegio = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getDepColegio() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.deptoscolegio = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getMedio() {
            this.ss.listMedio().then(
                (result) => {
                    let response = result.data;
                    this.medios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getGestion() {
            this.ss.listGestion().then(
                (result) => {
                    let response = result.data;
                    this.gestiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

	},
	mounted() {
        // location.reload();
        window.scrollTo(0, 0);
		// this.getProgramasPost();
		this.auth = JSON.parse(localStorage.getItem('persona'));
		this.persona = JSON.parse(localStorage.getItem('persona'));
        this.getCarrerasPorUnidadAcademica();
		this.getTiposEstudios();
		this.verificarDatosCompletos();
        this.getTipoColegio();
        this.getDepColegio();
        this.getMedio();
        this.getGestion();
       
        // console.log(this.auth);
        // if (!this.auth) {
        //     this.$router.push('/Login');
        // } else {
		// 	this.getProgramasPost();
        //     // this.showPersona();
        //     // this.getRol();
        //     // this.getUnidadAcademica();
        // }
	}
}