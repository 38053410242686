var axios = require('axios');
var axiosExterno = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    verificarCorreo(item) {
        // return axios.get('api/VerificarCorreo',  {params: {email: email}});
        return axios.post('api/VerificarCorreo', item);
    }

    verificarDatosPersona(id) {
        return axios.get('api/Persona/verificarDatos?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    storeDatosFactura(item) {
        return axios.post('api/Persona/datosFactura', item);
    }

    storePersonaExterno(item) {
        return axios.post('api/Persona/storeExterno', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    select2Persona(q) {
        return axios.get('api/Persona/select2?q=' + q);
    }

    insertarSaga(id) {
        return axios.get('api/Persona/insertarSaga', {params: {id: id}});
    }

    buscarSaga(params) {
        return axios.get('api/Persona/buscarSaga', {params: params});
    }
    
    registrarOffice365(id) {
        return axios.get('api/Persona/registrarOffice365', {params: {id: id}});
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    enviarCredenciales(id) {
        return axios.get('api/Persona/enviarCredenciales', {params: {id: id}});
    }



    listGrado(params) {
        return axios.get('api/Grado/list', {params: params});
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    listExternoUnidadAcademica() {
        return axios.get('api/listUnidadesAcademicas');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    showTipoNivelAcademico(id) {
        return axios.get('api/TipoNivelAcademico/show?id=' + id);
    }

    indexTipoNivelAcademico() {
        return axios.defaults.baseURL + 'api/TipoNivelAcademico/index';
    }

    listTipoNivelAcademico() {
        return axios.get('api/TipoNivelAcademico/list');
    }

    storeTipoNivelAcademico(item) {
        return axios.post('api/TipoNivelAcademico/store', item);
    }

    destroyTipoNivelAcademico(item) {
        return axios.post('api/TipoNivelAcademico/destroy', item);
    }

    showNivelAcademico(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexNivelAcademico() {
        return axios.defaults.baseURL + 'api/NivelAcademico/index';
    }

    listNivelAcademico() {
        return axios.get('api/NivelAcademico/list');
    }

    storeNivelAcademico(item) {
        return axios.post('api/NivelAcademico/store', item);
    }

    destroyNivelAcademico(item) {
        return axios.post('api/NivelAcademico/destroy', item);
    }


    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }


    showEspecialidadUA(id) {
        return axios.get('api/EspecialidadUA/show?id=' + id);
    }

    indexEspecialidadUA() {
        return axios.defaults.baseURL + 'api/EspecialidadUA/index';
    }

    listEspecialidadUA() {
        return axios.get('api/EspecialidadUA/list');
    }

    listPorCarreraEspecialidadUA(id) {
        return axios.get('api/EspecialidadUA/listPorCarrera', {params: {id : id}});
    }

    storeEspecialidadUA(item) {
        return axios.post('api/EspecialidadUA/store', item);
    }

    destroyEspecialidadUA(item) {
        return axios.post('api/EspecialidadUA/destroy', item);
    }

    borrarCarreraUnidadEspecialidadUA(id) {
        return axios.post('api/EspecialidadUA/borrarCarreraUnidad', {id: id});
    }

    indexPrograma() {
        return axios.defaults.baseURL + 'api/Programa/index';
    }


    buscarPagoSiscoin(codigo){
        return axiosExterno.get('api/Programa/verificarPago', {params: {codigo : codigo}});
    }

    listPrograma(params) {
        return axios.get('api/Programa/list', {params: params});
    }

    listVistaPostgradoPrograma() {
        return axios.get('api/listVistaPostgrado');
    }

    listVistaPostgradoporUA(params) {
        return axios.get('api/listVistaPostgradoporUA', {params: params});
    }

    listVistaPostgradoporUAyTipo(params) {
        return axios.get('api/listVistaPostgradoporUAyTipo', {params: params});
    }

    listGestiones() {
        return axios.get('api/Programa/listGestiones');
    }

    showPrograma(id){
        return axios.get('api/Programa/show', {params: {id: id}});
    }

    showProgramaPostPrograma(id){
        return axios.get('api/showProgramaPost', {params: {id: id}});
    }

    storePrograma(item){
        return axios.post('api/Programa/store', item);
    }

    destroyPrograma(item) {
        return axios.post('api/Programa/destroy', item);
    }

    postergacionCurso(item) {
        return axios.post('api/Programa/postergacionCurso', item);
    }


    indexProgramaPersona() {
        return axios.defaults.baseURL + 'api/ProgramaPersona/index';
    }

    indexInteresados() {
        return axios.defaults.baseURL + 'api/ProgramaPersona/interesados';
    }

    listProgramaPersona() {
        return axios.get('api/ProgramaPersona/list');
    }

    listInscripciones(params) {
        return axios.get('api/ProgramaPersona/listInscripciones', {params: params});
    }

    showProgramaPersona(id){
        return axios.get('api/ProgramaPersona/show', {params: {id: id}});
    }

    VerificarInscripcionProgramaPersona(params){
        return axios.get('api/ProgramaPersona/VerificarInscripcion', {params: params});
    }

    showInscripcionProgramaPersona(id){
        return axios.get('api/ProgramaPersona/showInscripcion', {params: {id: id}});
    }

    storeProgramaPersona(item){
        return axios.post('api/ProgramaPersona/store', item);
    }

    storeNuevaInscripcion(item){
        return axios.post('api/ProgramaPersona/storeNuevaInscripcion', item);
    }

    editarArchivosProgramaPersona(item){
        return axios.post('api/ProgramaPersona/editarArchivos', item);
    }

    destroyProgramaPersona(item) {
        return axios.post('api/ProgramaPersona/destroy', item);
    }

    showDetallePersonaPrograma(params){
        return axios.get('api/ProgramaPersona/showDetalleProgramaInscrito', {params: params});
    }

    enviarFinalizacionInscripcion(id){
        return axios.get('api/ProgramaPersona/enviarFinalizacionInscripcion', {params: {id: id}});
    }

    showTipoEstudio(id) {
        return axios.get('api/TipoEstudio/show?id=' + id);
    }

    indexTipoEstudio() {
        return axios.defaults.baseURL + 'api/TipoEstudio/index';
    }

    listTipoEstudio() {
        return axios.get('api/TipoEstudio/list');
    }

    storeTipoEstudio(item) {
        return axios.post('api/TipoEstudio/store', item);
    }

    destroyTipoEstudio(item) {
        return axios.post('api/TipoEstudio/destroy', item);
    }



    showDatoAcademico(id) {
        return axios.get('api/DatoAcademico/show?id=' + id);
    }

    indexDatoAcademico() {
        return axios.defaults.baseURL + 'api/DatoAcademico/index';
    }

    listDatoAcademico() {
        return axios.get('api/DatoAcademico/list');
    }

    storeDatoAcademico(item) {
        return axios.post('api/DatoAcademico/store', item);
    }

    destroyDatoAcademico(item) {
        return axios.post('api/DatoAcademico/destroy', item);
    }

    listPostgradualesDatoAcademico(id) {
        return axios.get('api/DatoAcademico/listPostgraduales', {params: {IdPersona : id}});
    }

    listGradoDatoAcademico(id) {
        return axios.get('api/DatoAcademico/listGrado', {params: {IdPersona : id}});
    }

    listEscolarDatoAcademico(id) {
        return axios.get('api/DatoAcademico/listEscolar', {params: {IdPersona : id}});
    }

    indexSinValidarVerificarDare() {
        return axios.defaults.baseURL + 'api/VerificarDare/indexSinValidar';
    }

    indexValidadosVerificarDare() {
        return axios.defaults.baseURL + 'api/VerificarDare/indexValidados';
    }

    validarDare(item) {
        return axios.post('api/VerificarDare/validarDare', item);
    }

    enviarObservacionDare(item) {
        return axios.post('api/VerificarDare/observacionDare', item);
    }

    indexVerificarTesoreria(filtros) {
        return axios.get('api/VerificarTesoreria/index', {params: filtros});
    }

    indexSinValidarTesoreria() {
        return axios.defaults.baseURL + 'api/VerificarTesoreria/indexSinValidar';
    }

    indexValidadosTesoreria() {
        return axios.defaults.baseURL + 'api/VerificarTesoreria/indexValidados';
    }

    validarTesoreria(item) {
        return axios.post('api/VerificarTesoreria/validarTesoreria', item);
    }


    enviarObservacionTesoreria(item) {
        return axios.post('api/VerificarTesoreria/observacionTesoreria', item);
    }

    // buscarSiscoin(CI){
    //     return axiosExterno.get('http://devel.emi.edu.bo/testInformix/'+CI);
    // }

    buscarSiscoin(CI){
        return axiosExterno.get('api/Persona/ps', {params: {ciPersona : CI}});
    }

    insertarSiscoin(item) {
        return axios.get('api/Persona/insertarSiscoin', {params: item});
    }

    insertarKardex(item) {
        return axios.post('api/Persona/registrarKardex', item);
    }

    // insertarSiscoin(item) {
    //     return axios.post('http://devel.emi.edu.bo/nuevos/insertaInformix/', item);
    // }


    OrderKE(item) {
        return axios.post('api/OrderKE/nuevaOrden', item);
    }

    pagarDeNuevo(item) {
        return axios.post('api/OrderKE/pagarNuevamente', item);
    }

    notificarPago(item) {
        return axios.post('api/Pagos/notificarPago', item);
    }




    showTipoColegio(id) {
        return axios.get('api/TipoColegio/show?id=' + id);
    }

    indexTipoColegio() {
        return axios.defaults.baseURL + 'api/TipoColegio/index';
    }

    listTipoColegio() {
        return axios.get('api/TipoColegio/list');
    }

    storeTipoColegio(item) {
        return axios.post('api/TipoColegio/store', item);
    }

    destroyTipoColegio(item) {
        return axios.post('api/TipoColegio/destroy', item);
    }


    showDepartamento(id) {
        return axios.get('api/Departamento/show?id=' + id);
    }

    indexDepartamento() {
        return axios.defaults.baseURL + 'api/Departamento/index';
    }

    listDepartamento() {
        return axios.get('api/Departamento/list');
    }

    storeDepartamento(item) {
        return axios.post('api/Departamento/store', item);
    }

    destroyDepartamento(item) {
        return axios.post('api/Departamento/destroy', item);
    }




    showGestion(id) {
        return axios.get('api/Gestion/show?id=' + id);
    }

    indexGestion() {
        return axios.defaults.baseURL + 'api/Gestion/index';
    }

    listGestion() {
        return axios.get('api/Gestion/list');
    }

    storeGestion(item) {
        return axios.post('api/Gestion/store', item);
    }

    destroyGestion(item) {
        return axios.post('api/Gestion/destroy', item);
    }



    showMedio(id) {
        return axios.get('api/Medio/show?id=' + id);
    }

    indexMedio() {
        return axios.defaults.baseURL + 'api/Medio/index';
    }

    listMedio() {
        return axios.get('api/Medio/list');
    }

    storeMedio(item) {
        return axios.post('api/Medio/store', item);
    }

    destroyMedio(item) {
        return axios.post('api/Medio/destroy', item);
    }



}