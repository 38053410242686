<template>
  <!-- Image and text  bg-transparent-->

<div>
  


    <div>
        <b-navbar toggleable="lg" type="dark"  class="fixed-top py-0" style="background-color:#11345585;">
            <b-navbar-brand class="ml-4">
                 <router-link
                    class="waves-effect"
                    to="Inscribete"
                    active-class="active"
                >
                    <img
                    src="/img/icons/logo_emi.png"
                    width="120"
                    height="60"
                    alt=""
                    />
                </router-link>
            </b-navbar-brand>
            

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>


            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                 <b-nav-item>
                     <router-link
                        class="waves-effect text-white"
                        to="Inscribete"
                        active-class="active"
                        >
                        INICIO
                    </router-link>
                 </b-nav-item>
                <b-nav-item v-if="auth.id != null" >
                   
                    <router-link
                        class="waves-effect text-white"
                        to="MisProgramas"
                        active-class="active"
                        >
                        MIS PROGRAMAS
                    </router-link>
                    
                </b-nav-item>
                <b-nav-item v-if="auth.id == null" @click="abrirModalRegistro" >REGISTRARSE</b-nav-item>
                <b-nav-item v-if="auth.id == null" @click="abrirModalLogin">LOGIN</b-nav-item>


                <!-- <b-nav-item-dropdown text="Lang" right>
                <b-dropdown-item href="#">EN</b-dropdown-item>
                <b-dropdown-item href="#">ES</b-dropdown-item>
                <b-dropdown-item href="#">RU</b-dropdown-item>
                <b-dropdown-item href="#">FA</b-dropdown-item>
                </b-nav-item-dropdown> -->

                <b-nav-item-dropdown right v-if="auth.id != null">
                <!-- Using 'button-content' slot -->
                <template #button-content>
                    <em class="text-white">{{auth.Persona}}</em>
                </template>
                <b-dropdown-item >
                    <router-link
                        class="waves-effect nombreLogin text-dark"
                        to="MiPerfil"
                        active-class="active"
                        >
                        Ver perfil
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item @click="cerrarSesion">Cerrar Sesion</b-dropdown-item>
                </b-nav-item-dropdown>


                

            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>

    <!-- component ver programa postgrado -->


        <!-- <VerProgramaPostgradoPage v-if="mostrarVerPrograma"/> -->
        <!-- =======Modal Login ========= -->
        <b-modal ref="modalLogin" size="md" title="Login">
            <h3 class="text-center m-t-0 m-b-15">
                <a href="#" class="logo logo-admin"><img src="/img/icons/icon-256x256.png" alt="" style="width:250px;"></a>
            </h3>
            <h4 class="text-center m-t-0 app-name">Inscripciones</h4>

           
                <div class="form-group">
                    <div class="col-12">
                        <input class="form-control" type="text" required="" name="email" placeholder="Correo Electrónico" v-model="loginCredenciales.email">
                        
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-12">
                        <input class="form-control" type="password" required="" name="password" placeholder="Contraseña" v-model="loginCredenciales.password">
                        
                    </div>
                </div>

                
                
                <div class="form-group text-center m-t-40">
                    <div class="col-12">
                        <button class="btn btn-primary btn-block btn-md waves-effect waves-light" type="submit" @click.prevent="login">Iniciar Sesión</button>
                    </div>
                </div>
                
                <hr>

                <div class="text-center mx-3 bg-info  py-3"> 
                    <h5 class="mx-3 text-white">
                     <b> Aun no estas registrado? Puedes registrate dando click en el siguiente botón </b>
                    </h5>
                </div>
                
                <div class=" text-center mx-3 mt-3">
                     <!-- <a @click.prevent="abrirModalRegistro" class="btn btn-warning  px-3 py-2"> <b> Registrarme </b></a> -->
                      <button class="btn btn-danger btn-block btn-md waves-effect waves-light" type="submit" @click.prevent="abrirModalRegistro">Registrarme</button>
                </div>
          

            <template v-slot:modal-footer>
                <div class="row">
                
                </div>
            </template>       
        </b-modal>
        <!-- =======Modal Login ========= -->

        <!-- =======Modal Registrarse ========= -->
        <b-modal ref="modalRegistro" size="lg" title="RegistroDeUsuario">
            <template v-slot:modal-header>
                <h4>Formulario de Registro</h4>
            </template>
            <template v-slot:default>
                <div class="row">
                    <div class="form-group col-md-6">
                    <label for="Nombres"><i class="fas fa-asterisk text-danger"></i> Nombres </label>
                    <input  type="text" class="form-control text-uppercase" name="Nombres" v-model="nuevaPersona.Nombres"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.Nombres">
                        <li class="parsley-required">
                        {{ errorBag.Nombres }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6">
                    <label for="ApellidoPaterno"> <i class="fas fa-asterisk text-danger"></i> Apellido Paterno</label>
                    <input type="text" class="form-control text-uppercase" name="ApellidoPaterno" v-model="nuevaPersona.ApellidoPaterno"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.ApellidoPaterno">
                        <li class="parsley-required">
                        {{ errorBag.ApellidoPaterno }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6">
                    <label for="ApellidoMaterno"> <i class="fas fa-asterisk text-danger"></i> Apellido Materno </label>
                    <input type="text" class="form-control text-uppercase" name="ApellidoMaterno" v-model="nuevaPersona.ApellidoMaterno"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.ApellidoMaterno">
                        <li class="parsley-required">
                        {{ errorBag.ApellidoMaterno }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6 col-sm-6">
                    <label for="ApellidoMaterno"> <i class="fas fa-asterisk text-danger"></i>  Carnet de identidad </label>
                    <input type="text" class="form-control" v-model="nuevaPersona.CI"/>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.CI">
                        <li class="parsley-required">
                        {{ errorBag.CI }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6 col-sm-6">
                    <label for="ApellidoMaterno"> <i class="fas fa-asterisk text-danger"></i>  Extención Carnet </label>
                    <select class="form-control" v-model="nuevaPersona.Depto">
                        <option value="1">La Paz</option>
                        <option value="3">Santa Cruz</option>
                        <option value="7">Cochabamba</option>
                        <option value="4">Oruro</option>
                        <option value="2">Chuquisaca</option>
                        <option value="6">Potosi</option>
                        <option value="8">Tarija</option>
                        <option value="5">Beni</option>
                        <option value="9">Pando</option>
                        <option value="10">RIB</option>
                        <option value="11">Extranjero</option>
                        <option value="12">Otro</option>
                    </select>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.Depto">
                        <li class="parsley-required">
                        {{ errorBag.Depto }}
                        </li>
                    </ul>
                    </div>

                    <!-- <div class="form-group col-md-6 col-sm-6">
                    <label > Estado Civil </label>
                    <select class="form-control" v-model="nuevaPersona.EstadoCivil">
                        <option value="Soltero/a">Soltero/a</option>
                        <option value="Casado/a">Casado/a</option>
                        <option value="Divorciado/a">Divorciado/a</option>
                        <option value="Viudo/a">Viudo/a</option>
                    </select>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.EstadoCivil">
                        <li class="parsley-required">
                        {{ errorBag.EstadoCivil }}
                        </li>
                    </ul>
                    </div> -->

                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="ApellidoMaterno"> Nacionalidad </label>
                    <select class="form-control" v-model="nuevaPersona.Nacionalidad">
                        <option value="Boliviana">Boliviana</option>
                        <option value="Extranjero">Extranjero</option>
                    </select>
                    </div>


                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                        <label> Fecha de nacimiento </label>
                        <input type="date" class="form-control" v-model="nuevaPersona.FechaNacimiento"/>
                    </div>

                    <div class="form-group col-md-6 col-sm-6">
                        <label ><i class="fas fa-asterisk text-danger"></i> Celular</label>
                        <input type="text" class="form-control" v-model="nuevaPersona.Celular"/>
                        <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.Celular">
                            <li class="parsley-required">
                            {{ errorBag.Celular }}
                            </li>
                        </ul>
                    </div>

                    

                </div>

                <!--
                <div class="form-group ">
                    <label>Unidad Academica</label>
                    <select
                        type="text" class="form-control" v-model="nuevaPersona.UnidadAcademica" @change="getCarrerasPorUnidadAcademica()">
                        <option :value="ua.id" v-for="ua in unidadAcademicas" v-bind:key="ua.id">
                        {{ ua.UnidadAcademica }}
                        </option>
                    </select>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.UnidadAcademica">
                        <li class="parsley-required">
                        {{ errorBag.UnidadAcademica }}
                        </li>
                    </ul>
                </div>

                <div class="form-group">
                    <label>Carrera que deseas estudiar</label>
                    <select
                        type="text" class="form-control" v-model="nuevaPersona.carrera2" >
                        <option :value="ca.id" v-for="ca in carreras" v-bind:key="ca.id">
                        {{ ca.NEspecialidad }}
                        </option>
                    </select>
                    <ul class="parsley-errors-list filled" id="parsley-id-19" v-if="errorBag.carrera2">
                        <li class="parsley-required">
                        {{ errorBag.carrera2 }}
                        </li>
                    </ul>
                </div>
                -->
                <div class="row">
                    <div class="form-group col-md-6">
                    <label for="email"> <i class="fas fa-asterisk text-danger"></i> Email </label>
                    <input
                        type="text"
                        class="form-control"
                        name="email"
                        v-model="nuevaPersona.email"
                    />
                    <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-19"
                        v-if="errorBag.email"
                    >
                        <li class="parsley-required">
                        {{ errorBag.email }}
                        </li>
                    </ul>
                    </div>
                    <div class="form-group col-md-6">
                    <label for="password"> <i class="fas fa-asterisk text-danger"></i> Password </label>
                    <input
                        type="password"
                        class="form-control"
                        name="password"
                        v-model="nuevaPersona.password"
                    />
                    <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-19"
                        v-if="errorBag.password"
                    >
                        <li class="parsley-required">
                        {{ errorBag.password }}
                        </li>
                    </ul>
                    </div>
                    
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                        <label > <i class="fas fa-asterisk text-danger"></i> Seleccione el grado academico en el que desea estudiar </label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="Tecnico" value="1" v-model="nuevaPersona.Tecnico" >
                            <label class="form-check-label">Tecnico Superior</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="Tecnico" value="0" v-model="nuevaPersona.Tecnico" >
                            <label class="form-check-label">Licenciatura</label>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                    <label > Sexo </label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="sexo" value="M" v-model="nuevaPersona.Sexo" >
                        <label class="form-check-label">Hombre</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="sexo" value="F" v-model="nuevaPersona.Sexo" >
                        <label class="form-check-label">Mujer</label>
                    </div>
                    </div>
                </div>
            </template>
            <template v-slot:modal-footer>
                <div class="row">
                    <!-- <a class="btn btn-warning m-2" @click="buscarSaga">Buscar Saga</a> -->
                    <a class="btn btn-danger m-2" @click="cancelPersona">Cancelar</a>
                    <!-- <a class="btn btn-success m-2" @click="buscarSaga">Guardar</a> -->
                    <a class="btn btn-success m-2" @click="verificarCorreo" :class="{disabled: botonGuardar}">Guardar</a>
                    <!-- <a class="btn btn-success m-2" @click="pruebacomponente">test</a> -->
                </div>
            </template>       
        </b-modal>
        <!-- =======Modal Registrarse ========= -->
</div>
    
  
  <!-- End Header -->
</template>

<script>
import MainService from "@/services/MainService.js";

export default {
    name: "MenuExterno",
  data() {
    
    return {
        ss : new MainService(),
        MenuExterno: {},
        ruta:process.env.VUE_APP,
        errorBag: {},
        nuevaPersona:{},
        loginCredenciales: {},
        auth: {},
        unidadAcademicas:[],
        mostrarVerPrograma:false,
        carreras: [],
        idProg:0,
        botonGuardar:false
    };
  },

  
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("persona"));
    console.log(this.auth);
    this.getUnidadAcademica();
    console.log("holaa");
  },

    created(){
        this.$root.$refs.MenuExterno=this;
    },

	methods: {

		cancelPersona(){
			this.nuevaPersona = {};
			this.$refs['modalRegistro'].hide();
        },
        
        // pruebacomponente(){
        //     this.$root.$refs.VerProgramaPostgrado.prueba("sebas");
        // },

        pruebasofi(){
            console.log("holasofi");
        },

        

        verificarCorreo() {
            this.botonGuardar=true;
            // console.log("verificar");
            // console.log(this.nuevaPersona);
            this.ss.verificarCorreo(this.nuevaPersona).then(
                (result) => {
                    // console.log(result);
                    let response = result.data;
                    console.log(response);
                    
                    if(response.success){
                        // console.log("el correo no existe");
                        this.buscarSaga();
                    }else{
                        // console.log("el correo existe");
                        this.$bvToast.toast(
                            'El correo o carnet ya se encuentra registrado',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.botonGuardar=false;
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Ocurrio un probela al verificar el correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
		savePersona() {

            // this.idProg = this.$route.query.id;
           
            // this.tiponivelacademico = {};
            // this.idProg = this.$route.query.id;
            console.log(this.$route.query.id)
            this.nuevaPersona.programaVisto=this.$route.query.id;

           
            this.ss.storePersonaExterno(this.nuevaPersona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
					)
					this.loginCredenciales.email = this.nuevaPersona.email;
					this.loginCredenciales.password = this.nuevaPersona.password;
					this.login();
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['modalRegistro'].hide();

                    this.$swal({
                        title: "Ya casi terminas tu registro ",
                        text: "Por favor completa tus datos, Luego podras inscribirte",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                this.$router.push('MiPerfil');
                                // this.$root.$refs.MenuExterno.abrirModalLogin();
                                // router.push("yourroutename");
                                    
                            } else {
                                //swal("Your imaginary file is safe!");
                            }
                        });
                    // this.$router.push('MiPerfil');
                    // window.location.href=window.location.origin+"/#/MiPerfil";
                   
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.botonGuardar=false;
		},
		login() {
			this.ss.login(this.loginCredenciales)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						console.log("logueado");
						this.login= true;
						this.$refs['modalLogin'].hide();
                        this.persona=result.data.data;
                        this.auth=result.data.data;
                        
                        this.loginCredenciales={};
                        
						//this.$router.push('/');
						/*var persona = JSON.parse(localStorage.getItem('persona'));
						console.log(persona)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
        },
        cerrarSesion() {
            localStorage.clear();
            this.auth={};
            this.$router.push("/inscribete");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
        },
		abrirModalLogin(){
			// this.tiponivelacademico = {};
			this.$refs['modalLogin'].show();
			
        },
        abrirModaReg(){
            
            this.nuevaPersona={};
			this.$refs['modalRegistro'].show();
			
		},
		abrirModalRegistro(){
			
            //console.log("abrir modal");
            this.nuevaPersona={};
			this.$refs['modalLogin'].hide();
            this.$refs['modalRegistro'].show();
        },
        getUnidadAcademica() {
            this.ss.listExternoUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    // this.unidadAcademicas = response.data;
                    let uas=response.data
                    this.unidadAcademicas = uas.splice(1, 4);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        buscarSaga() {
			let params = {
				CI: this.nuevaPersona.CI
			}
            this.ss.buscarSaga(params)
            .then(
                (result) => {
                    let response = result.data;
                    //Si encuentra al usuario...
                    if(response.success == true){
                        this.nuevaPersona.CodigoSaga = response.data.CodAlumno;
                        this.nuevaPersona.correoInstitucional = response.data.email_emi;
                        this.nuevaPersona.Celular = response.data.celular;
                        this.nuevaPersona.Telefono = response.data.Telefono;
                        this.nuevaPersona.Direccion = response.data.Direccion;
                    }
                    this.savePersona();
                    console.log(response);
                }
            )
            .catch(error => {
                console.log(error);
                this.savePersona();
               
            });
		},
        getCarrerasPorUnidadAcademica() {
            let idUA = this.nuevaPersona.UnidadAcademica;
            console.log(this.nuevaPersona.UnidadAcademica);
            this.ss.listPorCarreraEspecialidadUA(idUA).then(
                (result) => {
                    let response = result.data;
                    //console.log(response);
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
    },


};
</script>

<style scoped>
.nombreLogin{
    padding: 10px 0 10px 25px;
    color: white;
}

</style>