import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ProgramaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ProgramaPage",
            ss: ss,
            ajax: {
                "url": ss.indexPrograma(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Programa', name: 'Programa', title: 'Programa' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
                { data: 'NivelAcademico', name: 'NivelAcademico', title: 'Tipo Programa' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                { data: 'Periodo', name: 'Periodo', title: 'Periodo' },
                { data: 'CostoPrograma', name: 'CostoPrograma', title: 'Costo Programa' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadesAcademicas: [],
            nivelesAcademicos: [],
            programas: [],
            programa: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            auth:{}
        };
    },
    methods: {
        getUnidadesAcademicas() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadesAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getNivelesAcademicos() {
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.nivelesAcademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPrograma() {
            this.programa = {};
            this.$refs['frm-programa'].show();
        },
        showPrograma(id) {
            this.ss.showPrograma(id).then(
                (result) => {
                    let response = result.data;
                    this.programa = response.data;
                    this.$refs['view-programa'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        editPrograma() {
            this.$refs['frm-programa'].show();
            this.$refs['view-programa'].hide();
        },
        cancelPrograma() {
            if (this.programa.id) {
                this.$refs['view-programa'].show();
            }
            this.$refs['frm-programa'].hide();
        },
        savePrograma() {
            this.ss.storePrograma(this.programa).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-programa'].hide();
                    this.$refs['datatable-programa'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePrograma() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPrograma(this.programa)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-programa'].hide();
                                this.$refs['datatable-programa'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Programa').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPrograma(data);
            });
        },
        loadFileAfiche() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programa.Afiche = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFileTriptico() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.programa.Triptico = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        enviarPostergacion() {
            console.log("enviar correos de postregacion del programa");
            console.log(this.programa.id);
            this.ss.postergacionCurso(this.programa).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        buscarPagoSiscoin(){
            // this.botonSiscoin=true;
            this.ss.buscarPagoSiscoin(this.programa.CodigoCurso).then(
                (result) => {
                    // console.log("exito: ", result);
                    let response = result.data;
                    

                    console.log(response.data.success);


                    if(response.data.success){

                        console.log("el codigo de pago existe en el siscoin");
                        this.savePrograma();
                        // this.$bvToast.toast(
                        //     'El codigo ya esta registrada en el siscoin',
                        //     {
                        //         title: 'info',
                        //         variant: 'info',
                        //         autoHideDelay: 5000
                        //     }
                        // )

                        
                        // this.botonSiscoin=false;
                    }else{
                        // this.insertarSiscoin();
                        this.$swal({
                            title: "El codigo de pago al curso no existe",
                            text: "Por favor ingrese un codigo valido",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        })
                    }
                })
                .catch((error) => {
                    let errores = error.response;
                    console.log("los errores son:" , errores);
                    this.$bvToast.toast(
                        'Problema al buscar en el siscoin',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    // this.botonSiscoin=false;
                });
        },
        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));

        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getUnidadesAcademicas();
            this.getNivelesAcademicos();
        }
    }
};
