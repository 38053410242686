<template>
    <div id="app">
        <!-- Begin page -->
        <div id="wrapper">
            <menu-externo v-if="paginasSimples.includes(this.$router.currentRoute.name) && this.$router.currentRoute.name!=='Login'"/>
            <Topbar v-if="!paginasSimples.includes(this.$router.currentRoute.name)" />

            <Sidebar v-if="!paginasSimples.includes(this.$router.currentRoute.name)"/>
            <!-- ========== Left Sidebar end ========== -->

            <!-- ========== main content start ========== -->
            <div class="content-page">
                <!-- content -->
                <router-view></router-view>
                <!-- content -->
                <Footer v-if="!paginasSimples.includes(this.$router.currentRoute.name)"  />
                <footer-externo v-if="paginasSimples.includes(this.$router.currentRoute.name) && this.$router.currentRoute.name!=='Login'"/>
            </div>
            <!-- ====== main content end ========== -->
        </div>
    </div>
</template>

<script>
import Sidebar from "./partials/Sidebar";
import Topbar from "./partials/Topbar";
import Footer from "./partials/Footer";

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

export default {
    name: "app",
    data() {
        return {
            paginasSimples: 
            [
                'Login', 
                'Inscribete', 
                'VerPrograma',
                'MisProgramas',
                'MiPerfil',
                'DetalleMiPrograma'
            ],

        };
    },
    components: {
        Sidebar,
        Topbar,
        Footer,
        MenuExterno,
		FooterExterno,
    },
};
</script>


<style lang="scss">
@import "./assets/scss/app.scss";
</style>